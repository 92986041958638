<pds-module-header title="Compradores" ctaLabel="Novo Comprador" ctaPath="/compradores/pessoa-juridica/novo" *ngIf="buyers.state$ | async; let buyers">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel>
    <div class="buyer-list-search">
      <pds-form [formGroup]="form">
        <pds-form-row grid="2-1-1">
          <pds-form-field label="Busca">
            <input type="text" placeholder="Pesquise por CNPJ, nome comercial ou e-mail" formControlName="search" />
          </pds-form-field>

          <pds-form-field label="Status">
            <select formControlName="active">
              <option value="">Todos</option>
              <option value="true">Ativos</option>
              <option value="false">Inativos</option>
            </select>
          </pds-form-field>

          <pds-form-field label="Situação Cadastral">
            <select formControlName="status">
              <option value="">Todos</option>
              <option value="PENDING">Pendente de análise</option>
              <option value="REJECTED">Reprovados</option>
              <option value="APPROVED">Aprovados</option>
            </select>
          </pds-form-field>
        </pds-form-row>

        <pds-form-actions>
          <button pdsButton="default" title="Buscar" (click)="onClickSearch()">Buscar</button>
        </pds-form-actions>
      </pds-form>
    </div>

    <hr />

    @if (buyers.data?.length) {
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CNPJ</th>
            <th>Responsável</th>
            <th>E-mail</th>
            <th>Cadastro</th>
            <th>Situação</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for (buyer of buyers.data; track $index) {
          <tr data-testid="buyer-list-item">
            <td>
              <div class="flex flex-column gap-2">
                <strong>{{ buyer.companyName }}</strong>
                <small>{{ buyer.tradingName }}</small>
              </div>
            </td>
            <td>{{ buyer.cnpj }}</td>
            <td>{{ buyer.responsible.name }}</td>
            <td>{{ buyer.responsible.contacts | contacts : 'EMAIL' }}</td>
            <td>{{ buyer.audit?.createdAt | date : 'dd/MM/yyyy HH:mm' }}</td>
            <td>
              <span pds-chip="default">{{ buyer.administrativeStatus | adminStatus }}</span>
            </td>
            <td pdsColActions>
              <pds-switch (ngModelChange)="onChangeActive(buyer)" [(ngModel)]="buyer.active"></pds-switch>
            </td>
            <td pdsColActions>
              <button pdsButton="icon" (click)="onClickDetails(buyer)">
                <span class="icon">visibility</span>
              </button>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </pds-table>
    } @else if (buyers.data && !buyers.data.length) {
    <strong>Nenhum comprador encontrado.</strong>
    } @else if (buyers.loading) {
    <strong>carregando...</strong>
    } @else if (buyers.failed) {
    <peca-retry context="comprador" (retry)="onEventRetry()" />
    }
    <pds-pagination [pagination]="pagination$" (paginate)="onEventPaginate($event)" />
  </pds-panel>
</pds-module-header>

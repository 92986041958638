import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbItem, Pagination, PaginationInitialState } from '@peca/design-system';
import { Subject } from 'rxjs';
import { Async } from '@peca/backoffice/utils/async.util';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { BuyerNaturalPersonService } from '../../../services/buyer-natural-person.service';
import { BuyerNaturalPersonQuery } from '../../../models/buyer-natural-person-query.model';
import { BuyerNaturalPersonResponse } from '../../../models/buyer-natural-person-response.model';

@Component({
  selector: 'peca-buyer-natural-person-list',
  templateUrl: './buyer-natural-person-list.component.html',
  styleUrls: ['./buyer-natural-person-list.component.scss'],
})
export class BuyerNaturalPersonListComponent implements OnInit {
  breadcrumb: BreadcrumbItem[];
  buyerService: BuyerNaturalPersonService;
  pagination$: Subject<Pagination>;
  lastQuery: BuyerNaturalPersonQuery;
  buyers: Async<BuyerNaturalPersonResponse[]>;
  router: Router;
  form: FormGroup;

  constructor() {
    this.breadcrumb = [{ label: 'Compradores', path: '/compradores' }, { label: 'Pessoa Física' }];
    this.buyerService = inject(BuyerNaturalPersonService);
    this.pagination$ = new Subject<Pagination>();
    this.lastQuery = { ...PaginationInitialState, sort: 'audit.createdAt,desc' };
    this.buyers = new Async<BuyerNaturalPersonResponse[]>();
    this.router = inject(Router);
    this.form = new FormGroup({ search: new FormControl(''), active: new FormControl('') });
  }

  ngOnInit() {
    this.fetchBuyers(this.lastQuery);
  }

  fetchBuyers(query: BuyerNaturalPersonQuery) {
    this.lastQuery = query;
    this.buyers.loading();
    this.buyerService.readAll(query).subscribe({
      next: (response) => {
        this.paginate(response);
        this.buyers.loaded(response.items);
      },
      error: () => {
        this.buyers.failed();
      },
    });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onClickDetails(buyer: BuyerNaturalPersonResponse) {
    this.router.navigate(['compradores', 'pessoa-fisica', buyer.id]);
  }

  onClickSearch() {
    const { search, active } = this.form.value;
    const query = { ...PaginationInitialState, sort: 'audit.createdAt,desc', search, active };
    this.fetchBuyers(query);
    this.pagination$.next(PaginationInitialState);
  }

  onEventRetry() {
    this.fetchBuyers(this.lastQuery);
  }

  onEventPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchBuyers(query);
  }
}

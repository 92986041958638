<pds-module-header title="Compras">
  <pds-new-breadcrumb [items]="breadcrumb" />
  <pds-panel>
    <pds-form [formGroup]="form" (ngSubmit)="onSubmit()">
      <pds-form-row grid="2-1">
        <pds-form-field label="Vendedor">
          <input type="text" formControlName="search" />
        </pds-form-field>
        <pds-form-field label="Status">
          <select formControlName="status">
            <option value="">Todos</option>
            <option value="WAITING_CONFIRMATION ">{{ 'WAITING_CONFIRMATION' | status }}</option>
            <option value="IN_SEPARATION ">{{ 'IN_SEPARATION' | status }}</option>
            <option value="AWAITING_COLLECTION ">{{ 'AWAITING_COLLECTION' | status }}</option>
            <option value="DISPATCHED ">{{ 'DISPATCHED' | status }}</option>
            <option value="REFUSED ">{{ 'REFUSED' | status }}</option>
            <option value="CANCELED ">{{ 'CANCELED' | status }}</option>
            <option value="DELIVERED">{{ 'DELIVERED' | status }}</option>
          </select>
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <pds-button type="submit" [disabled]="form.pristine">Buscar</pds-button>
      </pds-form-actions>
    </pds-form>
    <hr />
    <ng-container *ngIf="orders$ | async; let orders">
      <ng-container *ngIf="orders.data && orders.data.length">
        <pds-table>
          <table>
            <thead>
              <tr>
                <th>Vendedor</th>
                <th>PA</th>
                <th>Status</th>
                <th>Data / Hora</th>
                <th>Valor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of orders.data">
                <td>
                  <div class="purchase-list-item__company-name">
                    <strong>{{ order.branch.companyName }}</strong>
                    <small>{{ order.branch.commercialName }} ({{ order.branch.externalReference }})</small>
                  </div>
                </td>
                <td>{{ order.order.commerceReference }}</td>
                <td>
                  <span [pdsChip]="order.status | statusColor">{{ order.status | status }}</span>
                </td>
                <td>{{ order.audit?.createdAt | date : 'dd/MM/yy HH:mm' }}</td>
                <td>{{ order.amount | currency : 'BRL' }}</td>
                <td pdsColActions>
                  <button pdsButton="icon" title="Baixar NFe" (click)="onClickDownloadNFe(order)">
                    <span class="icon">picture_as_pdf</span>
                  </button>
                  <button pdsButton="icon" title="Ver Detalhes" (click)="onClickDetails(order)">
                    <span class="icon">visibility</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </pds-table>
      </ng-container>
      <ng-container *ngIf="orders.data && !orders.data.length">
        <strong>Nenhuma ordem de compra encontrada.</strong>
      </ng-container>
      <ng-container *ngIf="orders.failure">
        <strong>Falha ao carregar ordens de compra.</strong>
      </ng-container>
      <ng-container *ngIf="orders.loading">
        <strong>carregando...</strong>
      </ng-container>

      <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)" />
    </ng-container>
  </pds-panel>
</pds-module-header>

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { AllowToDirective, KeycloakCredentialsService } from '@peca/keycloak';

interface MenuModule {
  path: string;
  label: string;
  icon: string;
  roles: string[];
}

interface MenuGroup {
  name: string;
  modules: MenuModule[];
}

@Component({
  selector: 'peca-main-navigation-menu',
  templateUrl: './main-navigation-menu.component.html',
  styleUrls: ['./main-navigation-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, AllowToDirective, DesignSystemModule],
})
export class MainNavigationMenuComponent implements OnInit {
  @Input()
  opened: boolean;
  expanded: boolean;
  groups: MenuGroup[];
  keycloakService: KeycloakCredentialsService;

  constructor() {
    this.keycloakService = inject(KeycloakCredentialsService);
    this.opened = false;
    this.expanded = true;
    this.groups = [
      {
        name: 'Cadastros',
        modules: [
          {
            path: 'vendedores',
            label: 'Vendedores',
            icon: 'storefront',
            roles: ['vendedores-listagem'],
          },
          {
            path: 'compradores',
            label: 'Compradores',
            icon: 'shopping_cart',
            roles: ['compradores-listagem'],
          },
          {
            path: 'transportadoras',
            label: 'Transportadoras',
            icon: 'local_shipping',
            roles: ['transportadoras-listagem'],
          },
          {
            path: 'b2b',
            label: 'B2B',
            icon: 'partner_exchange',
            roles: ['b2b-listagem'],
          },
          {
            path: 'cupons',
            label: 'Cupons',
            icon: 'sell',
            roles: ['cupons-listagem'],
          },
          {
            path: 'usuarios',
            label: 'Usuários',
            icon: 'manage_accounts',
            roles: ['usuarios-listagem'],
          },
          {
            path: 'canais-de-venda',
            label: 'Canais de venda',
            icon: 'real_estate_agent',
            roles: ['canal-de-venda-listagem'],
          },
        ],
      },
      {
        name: 'Operacional',
        modules: [
          {
            path: 'vendas',
            label: 'Vendas',
            icon: 'package_2',
            roles: ['vendas-listagem'],
          },
          {
            path: 'compras',
            label: 'Compras',
            icon: 'local_mall',
            roles: ['compras-listagem'],
          },
          {
            path: 'fretes',
            label: 'Fretes',
            icon: 'transportation',
            roles: ['fretes-listagem'],
          },
          {
            path: 'devolucoes',
            label: 'Devoluções',
            icon: 'cycle',
            roles: ['devolucoes-listagem'],
          },
          {
            path: 'entregas',
            label: 'Entregas',
            icon: 'local_shipping',
            roles: ['entregas-listagem'],
          },
        ],
      },
      {
        name: 'Consultas',
        modules: [
          {
            path: 'produtos',
            label: 'Produtos',
            icon: 'inventory_2',
            roles: ['produtos-consulta'],
          },
        ],
      },
    ];
  }

  ngOnInit(): void {
    const expandMenuPreference = localStorage.getItem('@peca.ai/backoffice/preferences/expand-menu');

    if (expandMenuPreference) {
      this.expanded = JSON.parse(expandMenuPreference);
    }
  }

  onClickToogleMenu() {
    this.expanded = !this.expanded;
    localStorage.setItem('@peca.ai/backoffice/preferences/expand-menu', `${this.expanded}`);
  }
}

import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: 'input[pdsCurrencyMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PDSCurrencyMaskDirective),
      multi: true,
    },
  ],
})
export class PDSCurrencyMaskDirective implements OnInit, OnDestroy {
  hostElementRef = inject(ElementRef);
  pipe = new CurrencyPipe('pt');
  onChange!: (value: string) => void;
  onTouched!: () => void;
  disabled$ = new BehaviorSubject<boolean>(false);
  renderer = inject(Renderer2);
  destroyRef$ = new Subject<void>();
  hasChange = false;
  previousValue: string | null = null;

  get value() {
    return this.hostElementRef.nativeElement.value || '';
  }

  set value(value: string) {
    this.hostElementRef.nativeElement.value = value;
  }

  ngOnInit() {
    this.disabled$.pipe(takeUntil(this.destroyRef$)).subscribe((disabled) => {
      if (disabled) {
        this.renderer.setAttribute(this.hostElementRef.nativeElement, 'disabled', 'disabled');
      } else {
        this.renderer.removeAttribute(this.hostElementRef.nativeElement, 'disabled');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  @HostListener('input', ['$event'])
  onKeydown() {
    const value = this.value;
    const sanitized = value.replace(/\./g, '').replace(/[^\d.]/g, '') || '';
    const fixed = parseFloat(sanitized) / 100;

    this.hasChange = true;
    this.value = this.applyCurrencyMask(fixed);
    this.onChange(this.value);
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    this.previousValue = this.value;
    this.hasChange = false;
    this.value = this.applyCurrencyMask(0);
    this.onChange(this.value);
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    if (this.hasChange || !this.previousValue) return;

    this.value = this.previousValue;
    this.hasChange = false;
    this.previousValue = null;
    this.onChange(this.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pasted = clipboardData?.getData('text').replace(/[^\d.,]/g, '') || '';
    const fixed = !pasted.includes(',') ? `${pasted},00` : pasted;
    const parsed = this.sanitize(fixed);

    this.hasChange = true;
    this.value = this.applyCurrencyMask(parsed);
    this.onChange(this.value);
    event.preventDefault();
  }

  writeValue(value: string | number): void {
    const parsed = typeof value === 'number' ? value : this.sanitize(value);
    this.value = this.applyCurrencyMask(parsed);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  applyCurrencyMask(value: number) {
    const formatted = this.pipe.transform(value || 0, 'BRL') || '';
    return decodeURI(encodeURI(formatted).replace(/%C2%A0/, '%20'));
  }

  private sanitize(value: string) {
    return Number(
      value
        ?.toString()
        .replace(/\./g, '')
        .replace(/,/g, '.')
        .replace(/[^\d.-]/g, '')
    );
  }
}

<pds-module-header title="Fretes">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <div class="delivery-list">
    <ng-container *ngIf="deliveries$ | async; let deliveries">
      <ng-container *ngIf="deliveries.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="deliveries.failure">
        <strong>Não foi possível carregar fretes.</strong>
      </ng-container>

      <ng-container *ngIf="deliveries.data && !deliveries.data.length">
        <div class="delivery-list__empty">
          <h2>Nenhum pedido aguardando frete</h2>
          <img src="/assets/img/no-freights.png" />
        </div>
      </ng-container>

      <ng-container *ngIf="deliveries.data && deliveries.data.length">
        <pds-table>
          <div class="delivery-list__actions">
            <pds-button (click)="fetchFreights()">
              <span class="flex-center gap-2">
                <span class="icon">refresh</span>
                <span>Atualizar</span>
              </span>
            </pds-button>
            <pds-button [disabled]="selecteds$ | async | size : 'lt' : 1" (click)="onClickGroupFreights()">
              <span class="flex-center gap-2">
                <span class="icon">deployed_code_update</span>
                <span>Solicitar entrega</span>
              </span>
            </pds-button>
          </div>
          <table>
            <thead>
              <tr>
                <th rowspan="2">Selecionar</th>
                <th rowspan="2">PA</th>
                <th rowspan="2" (click)="onClickSort(cols.createdAt)">
                  <div class="delivery-list__filter">
                    <span>Data</span>
                    <button [class.--in-use]="cols.createdAt.active">
                      <span class="icon">arrow_drop_down</span>
                    </button>
                  </div>
                </th>
                <th rowspan="2">Prazo</th>
                <th rowspan="2">Limite para ativar</th>
                <th rowspan="2">Transportadora</th>
                <th colspan="4">Rota</th>
              </tr>
              <tr>
                <th (click)="onClickSort(cols.origin)">
                  <div class="delivery-list__filter">
                    <span>Origem</span>
                    <button [class.--in-use]="cols.origin.active">
                      <span class="icon">arrow_drop_down</span>
                    </button>
                  </div>
                </th>
                <th>
                  <div class="delivery-list__filter" (click)="onClickSort(cols.destination)">
                    <span>Destino</span>
                    <button [class.--in-use]="cols.destination.active">
                      <span class="icon">arrow_drop_down</span>
                    </button>
                  </div>
                </th>
                <th>Direção</th>
                <th>Distância</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let delivery of deliveries.data">
                <td pdsColActions>
                  <pds-checkbox [(ngModel)]="delivery.selected" (ngModelChange)="onSelectDelivery(delivery)" />
                </td>
                <td>{{ delivery.order.commerceReference }}</td>
                <td>{{ delivery.createdAt | date : 'dd/MM/yy HH:mm' }}</td>
                <td>{{ delivery.deadlineHours }}h</td>
                <td>
                  <span class="delivery-chip" [ngStyle]="delivery | activationTimeColor">{{ delivery | activationTime }}</span>
                </td>
                <td>{{ delivery.carrier.companyName }}</td>
                <td>
                  <div class="delivery-list__address">
                    <strong>{{ delivery.origin.branchTradingName }} - {{ delivery.origin.branchCommercialName }}</strong>
                    <span>{{ delivery.origin.address | deliveryAddress }}</span>
                  </div>
                </td>
                <td>
                  <div class="delivery-list__address">
                    <strong>{{ delivery.buyer.companyName }}</strong>
                    <span>{{ delivery.shippingAddress | deliveryAddress }}</span>
                  </div>
                </td>
                <td>{{ delivery.direction | direction }}</td>
                <td>{{ delivery.distanceKm | number : '1.1-1' }}km</td>
              </tr>
            </tbody>
          </table>
        </pds-table>
      </ng-container>
    </ng-container>

    <peca-delivery-aggregation #aggregation (success)="onSuccess()" />
  </div>
</pds-module-header>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { LogotypeComponent } from './components/logotype/logotype.component';
import { SwitchComponent } from './components/switch/switch.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ButtonComponent } from './components/button/button.component';
import { TableComponent } from './components/table/table.component';
import { FormComponent } from './components/form/form/form.component';
import { FormRowComponent } from './components/form/form-row/form-row.component';
import { FormFieldComponent } from './components/form/form-field/form-field.component';
import { PanelComponent } from './components/panel/panel.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './components/alert/alert.component';
import { FormValidationPipe } from './pipes/form-validation/form-validation.pipe';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormSectionComponent } from './components/form/form-section/form-section.component';
import { FormActionsComponent } from './components/form/form-actions/form-actions.component';
import { NewBreadcrumbComponent } from './components/new-breadcrumb/breadcrumb.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { MaskDirective } from './directives/mask/mask.directive';
import { FormImmutableDirective } from './components/form/form-immutable/form-immutable.directive';
import { TableColActionsDirective } from './components/table/table-action.directive';
import { NumberMaskDirective } from './directives/number-mask/number-mask.directive';
import { PDSChipDirective } from './directives/chip/chip.directive';
import { PDSChipLinkDirective } from './directives/chip-link/chip-link.directive';
import { PDSButtonDirective } from './directives/button/button.directive';
import { PDSSelectComponent } from './components/select/select.component';
import { PDSLoaderComponent } from './components/loader/loader.component';
import { ModuleRolesPipe } from './pipes/module-roles/module-roles.pipe';
import { ModuleHeaderComponent } from './components/module-header/module-header.component';
import { PDSTooltipDirective } from './directives/tooltip/tooltip.directive';
import { PDSCurrencyMaskDirective } from './directives/currency-mask/currency-mask.directive';

@NgModule({
  imports: [CommonModule, FormsModule, RouterLink, ReactiveFormsModule, MultipleSelectComponent, TabsComponent],
  declarations: [
    LogotypeComponent,
    SwitchComponent,
    CheckboxComponent,
    CheckboxComponent,
    TableComponent,
    FormComponent,
    FormRowComponent,
    FormFieldComponent,
    PanelComponent,
    PaginationComponent,
    AutocompleteComponent,
    AlertComponent,
    MaskDirective,
    FormValidationPipe,
    ModalComponent,
    FormSectionComponent,
    FormActionsComponent,
    NewBreadcrumbComponent,
    DateInputComponent,
    FormImmutableDirective,
    TableColActionsDirective,
    NumberMaskDirective,
    PDSChipDirective,
    PDSChipLinkDirective,
    PDSLoaderComponent,
    PDSButtonDirective,
    PDSSelectComponent,
    ModuleRolesPipe,
    ModuleHeaderComponent,
    PDSTooltipDirective,
    ButtonComponent,
    PDSCurrencyMaskDirective,
  ],
  exports: [
    DateInputComponent,
    LogotypeComponent,
    SwitchComponent,
    CheckboxComponent,
    CheckboxComponent,
    TableComponent,
    FormComponent,
    FormRowComponent,
    FormFieldComponent,
    PanelComponent,
    PaginationComponent,
    AutocompleteComponent,
    AlertComponent,
    MaskDirective,
    FormValidationPipe,
    MultipleSelectComponent,
    TabsComponent,
    ModalComponent,
    FormSectionComponent,
    FormActionsComponent,
    NewBreadcrumbComponent,
    FormImmutableDirective,
    TableColActionsDirective,
    NumberMaskDirective,
    PDSChipDirective,
    PDSChipLinkDirective,
    PDSLoaderComponent,
    PDSButtonDirective,
    PDSSelectComponent,
    ModuleRolesPipe,
    ModuleHeaderComponent,
    PDSTooltipDirective,
    ButtonComponent,
    PDSCurrencyMaskDirective,
  ],
})
export class DesignSystemModule {}

import { BehaviorSubject, switchMap } from 'rxjs';
import { DocumentPreviewComponent, ToastService, UserExpress } from '@peca/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem, ModalComponent, MultipleItem } from '@peca/design-system';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { AnalyticsService } from '@peca/commons';
import { FileUploadComponent } from '@peca/backoffice/components/file-upload/file-upload.component';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { Async } from '@peca/backoffice/utils/async.util';

import { BuyerRejectFormComponent } from '../../buyer-reject-form/buyer-reject-form.component';
import { Buyer } from '../../../models/buyer.model';
import { BuyerForm } from '../../../forms/buyer.form';
import { BuyerGroupSource } from './buyer-group';
import { BuyerLegalPersonService } from '../../../services/buyer-legal-person.service';
import { BuyerDocument } from '../../../models/buyer-document.model';

@Component({
  templateUrl: './buyer-legal-person-form.component.html',
  styleUrls: ['./buyer-legal-person-form.component.scss'],
})
export class BuyerLegalPersonFormComponent implements OnInit {
  @ViewChild('userExpress')
  userExpress!: ModalComponent;

  @ViewChild('buyerReject')
  buyerReject!: BuyerRejectFormComponent;

  @ViewChild('upload')
  upload!: FileUploadComponent;

  @ViewChild('filePreview')
  filePreview!: DocumentPreviewComponent;

  toast: ToastService;
  route: ActivatedRoute;
  router: Router;
  buyerService: BuyerLegalPersonService;
  confirmableActionService: ConfirmableActionService;
  buyer$: BehaviorSubject<AsyncState<Buyer>>;
  form: BuyerForm;
  buyerGroups$: BehaviorSubject<MultipleItem[]>;
  breadcrumb: BreadcrumbItem[];
  analytics: AnalyticsService;
  files: Async<BuyerDocument[]>;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.route = inject(ActivatedRoute);
    this.buyerService = inject(BuyerLegalPersonService);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.confirmableActionService = inject(ConfirmableActionService);
    this.buyer$ = new BehaviorSubject<AsyncState<Buyer>>({ loading: true });
    this.form = new BuyerForm();
    this.buyerGroups$ = new BehaviorSubject<MultipleItem[]>(BuyerGroupSource);
    this.breadcrumb = [
      { label: 'Compradores', path: '../..' },
      { label: 'Pessoa Jurídica', path: '..' },
    ];
    this.files = new Async<BuyerDocument[]>();
  }

  get buyerId() {
    return this.route.snapshot.params['id'] as string;
  }

  ngOnInit() {
    if (this.buyerId) {
      this.fetchBuyerDetails(this.buyerId);
    } else {
      this.breadcrumb.push({ label: 'Novo comprador' });
      this.buyer$.next({});
    }
  }

  uploadDocument(formData: FormData) {
    this.buyerService.uploadDocument(this.buyerId, formData).subscribe({
      next: () => {
        this.toast.success('Documento adicionado com sucesso.');
        this.fetchBuyerDetails(this.buyerId);
      },
      error: () => this.toast.failure('Falha ao adicionar o documento.'),
    });
  }

  fetchBuyerDetails(id: string) {
    this.buyer$.next({ loading: true });
    this.buyerService.fetchDetails(id).subscribe({
      next: ({ details, files }) => {
        this.breadcrumb.push({ label: details.companyName });
        this.buyer$.next({ data: details });
        this.files.loaded(files);
        this.form.setValue(details);
        this.form.group.disable();
      },
      error: () => this.buyer$.next({ failure: true }),
    });
  }

  create() {
    this.buyerService.create(this.form.value.create).subscribe({
      next: () => {
        this.toast.success('Comprador salvo com sucesso.');
        this.router.navigate(['compradores', 'pessoa-juridica']);
      },
      error: () => this.toast.failure('Falha ao criar comprador.'),
    });
  }

  update() {
    const { shippingAddress } = this.form.value.update;
    const update = this.buyerService.update(this.buyerId, this.form.value.update);
    const updateShippingAddress = this.buyerService.updateShippingAddress(this.buyerId, shippingAddress.id, shippingAddress);

    update.pipe(switchMap(() => updateShippingAddress)).subscribe({
      next: () => {
        this.toast.success('Comprador salvo com sucesso.');
        this.router.navigate(['compradores', 'pessoa-juridica']);
      },
      error: () => this.toast.failure('Falha ao alterar o comprador.'),
    });
  }

  removeDocument(fileId: string) {
    this.buyerService.deleteDocument(this.buyerId, fileId).subscribe({
      next: () => {
        this.fetchBuyerDetails(this.buyerId);
      },
      error: () => this.toast.failure('Falha ao remover o documento.'),
    });
  }

  onRetry() {
    this.fetchBuyerDetails(this.buyerId);
  }

  onClickEdit() {
    this.form.group.enable();

    if (this.form.controls.repeatAddresses.value) {
      this.form.controls.shippingAddress.disable();
    }
  }

  onClickDownload(id: string) {
    this.buyerService.downloadDocument(this.buyerId, id).subscribe({
      next: (url: unknown) => window.open(url as string),
      error: () => this.toast.failure('Não foi possível baixar a documento.'),
    });
  }

  onClickSave() {
    if (this.form.group.invalid) return;

    if (this.buyerId) {
      this.update();
    } else {
      this.create();
    }
  }

  onClickActivate() {
    const { id, active, companyName } = this.form.controls;
    const message = `Você está ${active ? 'desativando' : 'ativando'} o comprador ${companyName.value}.`;

    this.confirmableActionService.confirm(message, { id, active }).subscribe({
      next: ({ confirmed }) => {
        if (!confirmed) return;

        this.buyerService.toogleStatus(id.value, !active.value).subscribe({
          next: () => {
            this.toast.success('Status do comprador alterado com sucesso.');
            this.form.controls.active.setValue(!active);
          },
        });
      },
    });
  }

  onSetFinancingMethod(setted: boolean) {
    if (setted) {
      this.form.addBillingRecurrenceValidation();
    } else {
      this.form.removeBillingRecurrenceValidation();
    }
  }

  onCrateNewUser() {
    this.userExpress.open();
  }

  onUserCreated(user: UserExpress) {
    this.form.addFarmer({ id: user.id, name: `${user.firstName} ${user.lastName}`, email: user.email });
    this.userExpress.close();
  }

  onClickRemoveFarmer(id: string) {
    this.form.removeFarmer(id);
  }

  onClickReject() {
    if (!this.buyer$.value.data) return;
    this.buyerReject.open(this.buyer$.value.data);
  }

  onClickApprove() {
    if (!this.buyer$.value.data) return;

    this.confirmableActionService.confirm('Você está aprovando o comprador', null, true).subscribe(() => {
      this.buyerService.approve(this.buyerId).subscribe({
        next: () => {
          this.toast.success('Comprador aprovado com sucesso.');
          this.fetchBuyerDetails(this.buyerId);
        },
        error: () => this.toast.failure('Falha ao aprovar cadastro do comprador.'),
      });
    });
  }

  onAdminStatusReject() {
    this.router.navigate(['compradores', 'pessoa-juridica']);
  }

  onClickPreviewDocument(file: BuyerDocument) {
    this.buyerService.downloadFile(this.buyerId, file.id).subscribe({
      next: (url) => {
        this.filePreview.open({ id: file.id, title: file.name, type: file.type, url });
      },
      error: () => {
        this.toast.failure('Falha ao baixar documento.');
      },
    });
  }

  onEventUpload(file: File) {
    const formData = new FormData();

    formData.append(`files`, file);
    formData.append(`files[0].type`, file.type);
    formData.append('types', file.type);

    this.uploadDocument(formData);
  }

  onClickAttachDocument() {
    this.upload.open();
  }

  onEventDeleteFile(file: { id: string; title: string }) {
    const message = `Você está apagando o documento ${file.title}.`;
    this.confirmableActionService.confirm(message, file, true).subscribe((confirmation) => {
      if (!confirmation) {
        return;
      }

      this.removeDocument(file.id);
      this.filePreview.close();
    });
  }
}

import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@peca/commons';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';

import { SalesChannelsService } from '../../services/sales-channels.service';
import { SalesChannelsQuery } from '../../models/sales-channels-request.model';
import { Pageable } from '../../../../core/models/pageable.model';
import { AsyncState } from '../../../../core/models/async-state.model';
import { SalesChannel } from '../../models/sales-channels.model';
import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';

@Component({
  selector: 'peca-sales-channels-list',
  templateUrl: './sales-channels-list.component.html',
  styleUrls: ['./sales-channels-list.component.scss'],
})
export class SalesChannelsListComponent implements OnInit {
  router: Router;
  toast: ToastService;
  confirmable: ConfirmableActionService;
  destroyRef$: Subject<void>;
  service: SalesChannelsService;
  breadcrumbItems: BreadcrumbItem[];
  salesChannels$: BehaviorSubject<AsyncState<Pageable<SalesChannel>>>;
  pagination$: BehaviorSubject<Pagination>;
  lastQuery: SalesChannelsQuery;
  searchForm: FormGroup;

  constructor() {
    this.service = inject(SalesChannelsService);
    this.destroyRef$ = new Subject<void>();
    this.router = inject(Router);
    this.toast = inject(ToastService);
    this.confirmable = inject(ConfirmableActionService);
    this.breadcrumbItems = [{ label: 'Canais de Venda', path: '/canais-de-venda' }];
    this.salesChannels$ = new BehaviorSubject<AsyncState<Pageable<SalesChannel>>>({ loading: true });
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.lastQuery = { sort: 'audit.createdAt,desc', ...PaginationInitialState };
    this.searchForm = new FormGroup({ search: new FormControl(''), active: new FormControl('') });
  }

  ngOnInit() {
    this.fetch(this.lastQuery);
  }

  handlePaginateEvent(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetch(query);
  }

  fetch(query: SalesChannelsQuery) {
    this.lastQuery = query;
    this.service
      .fetch(this.lastQuery)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (data) => {
          this.paginate(data);
          this.salesChannels$.next({ data });
        },
        error: () => {
          this.salesChannels$.next({ failure: true });
        },
      });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onChangeSalesChannelStatus(salesChannel: SalesChannel) {
    this.confirmable.confirm('Você está alterando o status de um canal de venda.').subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return (salesChannel.active = !salesChannel.active);
      }

      this.service
        .updateStatus(salesChannel.id, salesChannel.active)
        .pipe(takeUntil(this.destroyRef$))
        .subscribe({
          error: (err) => {
            salesChannel.active = !salesChannel.active;
            this.toast.failure('Ocorreu um erro ao alterar o status do canal de venda');
          },
          complete: () => {
            this.toast.success('Status do canal de venda alterado com sucesso');
          },
        });
      return;
    });
  }

  onClickDetails(salesChannel: SalesChannel) {
    this.router.navigate(['/canais-de-venda', salesChannel.id]);
  }

  onClickRemove(salesChannel: SalesChannel) {
    this.confirmable.confirm('Você está deletando um canal de venda.').subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.service
        .delete(salesChannel.id)
        .pipe(takeUntil(this.destroyRef$))
        .subscribe({
          error: (err) => {
            this.toast.failure('Ocorreu um erro ao deletar o canal de venda');
          },
          complete: () => {
            this.toast.success('Canal de venda deletado com sucesso');
            this.fetch(this.lastQuery);
          },
        });
      return;
    });
  }

  onSubmitSearch() {
    if (this.searchForm.invalid) {
      return this.toast.failure('Filtro incorreto');
    }
    const { active, search } = this.searchForm.value;
    const query = { ...this.lastQuery, search, active };

    this.fetch(query as SalesChannelsQuery);
  }

  onClickResetRateLimit(channel: SalesChannel) {
    const message = `Você está reiniciando o limite de busca do canal "${channel.name}"`;
    this.confirmable.confirm(message, channel, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.service.refreshClientSecret(channel.id).subscribe({
        next: () => {
          this.toast.success('Limite de busca foi reiniciado com sucesso.');
        },
        error: () => {
          this.toast.failure('Falha ao reiniciar limite de busca.');
        },
      });
    });
  }
}

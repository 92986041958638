<pds-module-header title="Compradores" ctaLabel="Voltar" ctaPath=".." modifier="outlined" *ngIf="buyer$ | async as buyer">
  <pds-new-breadcrumb [items]="breadcrumb" *ngIf="!buyer.loading && !buyer.failure" />

  <pds-panel>
    <ng-container *ngIf="!buyer.loading && !buyer.failure">
      <pds-alert *ngIf="!form.controls.active.value" title="Comprador desativado" icon="warning" type="warning">
        <span
          >O comprador <strong>{{ form.controls.companyName.value }}</strong> está desativado.</span
        >
      </pds-alert>

      <pds-form [formGroup]="form.group">
        <pds-form-section title="Empresa">
          <pds-form-row grid="1-1-1">
            <pds-form-field label="CNPJ">
              <input type="text" formControlName="cnpj" pdsMask="99.999.999/9999-99" [pdsFormImmutable]="!!buyerId" />
            </pds-form-field>

            <pds-form-field label="Razão Social">
              <input type="text" formControlName="companyName" />
            </pds-form-field>

            <pds-form-field label="Nome Fantasia">
              <input type="text" formControlName="tradingName" />
            </pds-form-field>
          </pds-form-row>

          <pds-form-row>
            <pds-form-field label="Observações">
              <input type="text" formControlName="observations" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Contribuição Fiscal">
          <pds-form-row grid="1-1-1">
            <pds-form-field label="Inscrição Estadual" [hidden]="form.controls.freeTaxPayer.value">
              <input type="text" formControlName="stateRegistration" />
            </pds-form-field>
          </pds-form-row>
          <pds-form-row>
            <pds-checkbox label="O comprador é isento de contribuição fiscal" formControlName="freeTaxPayer" />
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Responsável">
          <peca-responsible-form formControlName="responsible" />
        </pds-form-section>

        <pds-form-section title="Representantes">
          <pds-form-row *ngIf="form.group.enabled" grid="1-1-1-1">
            <pds-form-field label="Adicionar">
              <peca-buyer-representative-form formControlName="representativesIds" (createNewUser)="onCrateNewUser()" />
            </pds-form-field>
          </pds-form-row>

          <pds-form-row>
            <ng-container *ngIf="!form.controls.representativesIds.value.length">
              <strong>Nenhum representante cadastrado.</strong>
            </ng-container>

            <ng-container *ngIf="form.controls.representativesIds.value.length">
              <pds-table>
                <table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let farmer of form.controls.representativesIds.value">
                      <td>{{ farmer.name }}</td>
                      <td>{{ farmer.email }}</td>
                      <td pdsColActions>
                        <button pdsButton="icon" [disabled]="form.group.disabled" title="Ver Detalhes" (click)="onClickRemoveFarmer(farmer.id)">
                          <span class="icon">remove</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </pds-table>
            </ng-container>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Endereço de cobrança">
          <peca-address formControlName="billingAddress" />
        </pds-form-section>

        <pds-form-section title="Endereço de entrega">
          <pds-checkbox formControlName="repeatAddresses" label="Utilizar endereço de cobrança" />
          <peca-address formControlName="shippingAddress" />
        </pds-form-section>

        <pds-form-section title="Pagamento">
          <pds-form-row>
            <pds-form-field label="Métodos de pagamento">
              <peca-payment-method-form formControlName="paymentMethodsIds" (financingSet)="onSetFinancingMethod($event)" />
            </pds-form-field>
          </pds-form-row>

          <pds-form-row>
            <pds-form-field label="Pagamento com títulos vencidos">
              <pds-checkbox label="Permitir uso do Financiamento com títulos vencidos" formControlName="allowFinancingWithOverdueBonds" />
              <pds-checkbox label="Permitir pagamento por cartão de crédito ou PIX com título vencidos" formControlName="allowPurchasesWithOverdueBonds" />
            </pds-form-field>
          </pds-form-row>

          <pds-form-row grid="1-1-1-1">
            <pds-form-field label="Recorrência de cobrança">
              <select formControlName="billingRecurrence">
                <option value="" disabled>Selecione</option>
                <option value="DAILY">Diária</option>
                <option value="WEEKLY">Semanal</option>
              </select>
            </pds-form-field>

            <pds-form-field label="Limite de crédito">
              <input type="text" formControlName="creditLimit" pdsCurrencyMask />
            </pds-form-field>

            <pds-form-field *ngIf="!!this.buyerId" label="Última analise de crédito">
              <input type="text" [value]="form.controls.lastCreditAnalysis.value | date : 'dd/MM/yyyy HH:mm'" disabled />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Oficinas">
          <pds-form-row grid="1-1-1-1">
            <pds-form-field label="Rede">
              <pds-multiple-select formControlName="buyersGroups" [source]="buyerGroups$" />
            </pds-form-field>

            <pds-form-field label="Tamanho da oficina">
              <select form="officeSize" formControlName="officeSize">
                <option value="" disabled selected>Selecione</option>
                <option value="PEQUENO">Pequeno</option>
                <option value="MÉDIO">Médio</option>
                <option value="GRANDE">Grande</option>
              </select>
            </pds-form-field>

            <pds-form-field label="Média de atendimentos por dia">
              <input type="number" formControlName="averageDailyOccurrences" placeholder="Média de atendimentos" />
            </pds-form-field>

            <pds-form-field label="Ramo de atividade">
              <select formControlName="branchOfActivity">
                <option value="" disabled selected>Selecione</option>
                <option value="auto_eletrica">Auto Elétrica</option>
                <option value="auto_vidro">Auto Vidro</option>
                <option value="borracharia">Borracharia</option>
                <option value="centro_automotivo">Centro automotivo</option>
                <option value="concessionaria">Concessionária</option>
                <option value="freio_e_suspensao">Freio e Suspensão</option>
                <option value="funilaria_e_pintura">Funilaria e Pintura</option>
                <option value="motor">Motor</option>
                <option value="oficina_mecanica">Oficina Mecânica</option>
                <option value="oficina_de_acessorios">Oficina de Acessórios</option>
                <option value="posto_de_combustivel">Posto de Combustível</option>
                <option value="seguradora">Seguradora</option>
                <option value="servicos_especializados">Serviços Especializados (Climatização, Escapamento, outros)</option>
                <option value="super_troca_de_oleo">Super troca de óleo</option>
                <option value="tunning">Tunning (Preparação e Adaptação)</option>
                <option value="NA">NA</option>
              </select>
            </pds-form-field>
          </pds-form-row>

          <pds-form-row grid="1-1-1-1">
            <pds-form-field label="Número de funcionários">
              <input type="number" formControlName="employeeCount" />
            </pds-form-field>
            <pds-form-field label="Carros atendidos por dia">
              <input type="number" formControlName="carServicedPerDay" />
            </pds-form-field>
            <pds-form-field label="Número de elevadores">
              <input type="number" formControlName="elevatorCount" />
            </pds-form-field>
            <pds-form-field label="Como conheceu o Peça Aí?">
              <select formControlName="origin">
                <option disabled value="">Selecione</option>
                <option value="PECA_AI_CONSULTANT">Consultor Peça Aí</option>
                <option value="FAIR_EVENT">Feira / Evento</option>
                <option value="GOOGLE">Google</option>
                <option value="SOCIAL_MEDIA">Redes Sociais</option>
                <option value="WORKSHOP_RECOMMENDATION">Indicação Oficina</option>
                <option value="BOSCH_CONSULTANT">Consultor Bosch</option>
                <option value="OTHER">Outros</option>
              </select>
            </pds-form-field>
          </pds-form-row>

          <pds-form-row>
            <pds-form-field label="Itens em estoque na oficina">
              <peca-buyer-product-types formControlName="productTypes" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        @if (!!buyerId) {
        <pds-form-section *ngIf="!!buyerId" title="Documentos">
          <div *ngIf="files.state$ | async; files" class="flex wrap gap-2">
            @for (file of files.data; track $index) {
            <span [pdsChip]="file.status === 'UPLOADED' ? 'terciary' : 'default'" [icon]="file.status === 'UPLOADED' ? 'open_in_new' : 'progress_activity'" (click)="onClickPreviewDocument(file)" class="cursor-pointer">
              <span class="text-truncate">{{ file.name }}</span>
            </span>
            } @empty {
            <strong>Nenhum documento anexado.</strong>
            }
          </div>
        </pds-form-section>
        }

        <hr />

        <pds-alert *ngIf="buyer.data?.administrativeStatus === 'PENDING'" title="Comprador em análise" icon="warning" type="error">
          <span
            >O comprador <strong>{{ form.controls.companyName.value }}</strong> está em análise. Até que o cadastro sejá aprovado não será possível realizar alterações.</span
          >
        </pds-alert>

        <pds-form-actions>
          <pds-button *ngIf="!!buyerId && buyer.data?.administrativeStatus !== 'PENDING'" modifier="outlined" (click)="onClickActivate()">{{ form.controls.active.value ? 'Desativar' : 'Ativar' }}</pds-button>
          <pds-button *ngIf="!!buyerId && buyer.data?.administrativeStatus !== 'PENDING'" (click)="onClickAttachDocument()" title="Adicionar Documento">adicionar documento</pds-button>
          <pds-button *ngIf="!!buyerId && form.group.disabled && buyer.data?.administrativeStatus !== 'PENDING'" (click)="onClickEdit()">editar</pds-button>
          <pds-button *ngIf="form.group.enabled && buyer.data?.administrativeStatus !== 'PENDING'" [disabled]="form.group.invalid" (click)="onClickSave()">salvar</pds-button>
          <pds-button *ngIf="buyer.data?.administrativeStatus === 'PENDING'" modifier="outlined" (click)="onClickReject()">recusar</pds-button>
          <pds-button *ngIf="buyer.data?.administrativeStatus === 'PENDING'" (click)="onClickApprove()">aprovar</pds-button>
        </pds-form-actions>
      </pds-form>
    </ng-container>

    <ng-container *ngIf="buyer.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="buyer.failure">
      <peca-retry context="detalhes do comprador" (retry)="onRetry()" />
    </ng-container>
  </pds-panel>

  <pds-modal #userExpress [width]="640" title="Cadastro expresso de usuário">
    <peca-user-express (created)="onUserCreated($event)" />
  </pds-modal>
  <peca-buyer-reject-form #buyerReject (success)="onAdminStatusReject()" />
  <peca-file-upload #upload (upload)="onEventUpload($event)" />
  <peca-document-preview #filePreview (delete)="onEventDeleteFile($event)" />
</pds-module-header>

<nav class="main-navigation-menu" [class.--is-opened]="opened">
  <div class="main-navigation-menu__wrapper">
    <ul class="main-navigation-menu__groups">
      <li *ngFor="let group of menu">
        <ng-container *allowTo="[group | moduleRoles]">
          <h4 class="main-navigation-menu__groups__name" [class.--is-expanded]="expanded">{{ group.name }}</h4>

          <ul class="main-navigation-menu__modules">
            <ng-container *ngFor="let module of group.modules">
              <li class="main-navigation-menu__item" routerLinkActive="--is-active" *allowTo="[module.roles]">
                <a class="main-navigation-menu__link" [routerLink]="[module.path]" [class.--is-expanded]="expanded">
                  <div class="main-navigation-menu__link__icon">
                    <span class="icon">{{ module.icon }}</span>
                  </div>
                  <div class="main-navigation-menu__link__label">
                    <span class="label">{{ module.label }}</span>
                  </div>
                </a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </li>
    </ul>
  </div>

  <button type="menu" class="main-navigation-menu__trigger" [class.--is-expanded]="expanded" (click)="onClickToogleMenu()">
    <span class="icon">keyboard_double_arrow_right</span>
  </button>
</nav>

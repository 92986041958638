<pds-module-header title="Compradores" ctaLabel="Novo Comprador" ctaPath="/compradores/pessoa-fisica/novo">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel *ngIf="buyers.state$ | async; let buyers">
    <pds-form [formGroup]="form">
      <pds-form-row grid="2-1">
        <pds-form-field label="Busca">
          <input type="text" formControlName="search" placeholder="Busque por nome, email ou CPF" />
        </pds-form-field>

        <pds-form-field label="Status">
          <select formControlName="active">
            <option value="">Todos</option>
            <option value="true">Ativos</option>
            <option value="false">Inativos</option>
          </select>
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <button pdsButton type="button" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    @if (buyers.data && buyers.data.length) {
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Celular</th>
            <th>E-mail</th>
            <th>Data de Cadastro</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for (item of buyers.data; track $index) {
          <tr>
            <td>{{ item.name }} {{ item.surname }}</td>
            <td>{{ item.cpf }}</td>
            <td>{{ item.cellphone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.audit?.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
            <td pdsColActions>
              <button pdsButton="icon" title="Ver Detalhes" (click)="onClickDetails(item)">
                <span class="icon">visibility</span>
              </button>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </pds-table>
    }@else if (buyers.data && !buyers.data.length) {
    <strong>Nenhum comprador encontrado.</strong>
    } @else if (buyers.loading) {
    <strong>carregando...</strong>
    } @else if (buyers.failed) {
    <peca-retry context="Compradores" (retry)="onEventRetry()" />
    }

    <pds-pagination [pagination]="pagination$" (paginate)="onEventPaginate($event)" />
  </pds-panel>
</pds-module-header>

import { Routes } from '@angular/router';
import { BuyersComponent } from './components/buyers/buyers.component';
import { BuyerNaturalPersonFormComponent } from './components/buyer-natural-person/buyer-natural-person-form/buyer-natural-person-form.component';
import { BuyerNaturalPersonListComponent } from './components/buyer-natural-person/buyer-natural-person-list/buyer-natural-person-list.component';
import { BuyerLegalPersonListComponent } from './components/buyer-legal-person/buyer-legal-person-list/buyer-legal-person-list.component';
import { BuyerLegalPersonFormComponent } from './components/buyer-legal-person/buyer-legal-person-form/buyer-legal-person-form.component';
import { keycloakGuard } from '@peca/keycloak';

export const routes: Routes = [
  {
    path: 'pessoa-fisica/novo',
    component: BuyerNaturalPersonFormComponent,
    canActivate: [keycloakGuard(['compradores-pf-listagem'])],
  },
  {
    path: 'pessoa-fisica/:id',
    component: BuyerNaturalPersonFormComponent,
    canActivate: [keycloakGuard(['compradores-pf-listagem'])],
  },
  {
    path: 'pessoa-fisica',
    component: BuyerNaturalPersonListComponent,
    canActivate: [keycloakGuard(['compradores-pf-listagem'])],
  },
  {
    path: 'pessoa-juridica/novo',
    component: BuyerLegalPersonFormComponent,
  },
  {
    path: 'pessoa-juridica/:id',
    component: BuyerLegalPersonFormComponent,
  },
  {
    path: 'pessoa-juridica',
    component: BuyerLegalPersonListComponent,
  },
  {
    path: '',
    component: BuyersComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const BuyersModuleLoad = () => import('./buyers.module').then(({ BuyersModule }) => BuyersModule);
